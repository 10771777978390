export default {
  openedCase: 'Açılan Kasa',
  awardGiven: 'Verilen Ödül',
  openCaseBtnLabel: 'Kasayı Aç',
  openCase: 'Kasa Aç {caseAmount}',
  winners: 'Son Kazananlar',
  myBets: 'Bahislerim',
  caseOpenAmount: 'Açma Bedeli',
  followingAwards: 'Kasa Aşağıdaki Ödülleri İçerir',
  congrats: 'Tebrikler! {amount} Kazandınız',
  increaseLuck: 'Şansını arttır',
  increaseLuckAmount: '+{amount}% Arttır',
  instantOpening: 'Anlık Açılış',
  autoOpening: 'Oto Açılış',
  autoSpin: 'Otomatik Çevir',
  spinLeft: '({amount}) Çevrim hakkın kaldı',
  caseType: 'Kasa Tipi: {type}',
}

export default {
  openedCase: 'Opened Case',
  awardGiven: 'Award Given',
  openCaseBtnLabel: 'Open Case',
  openCase: 'Open Case {caseAmount}',
  winners: 'Winners',
  myBets: 'My Bets',
  caseOpenAmount: 'Amount',
  followingAwards: 'The Case Contains Following Awards',
  congrats: 'Congratulations! You won {amount}',
  increaseLuck: 'Increase Your Luck',
  increaseLuckAmount: 'Increase +{amount}%',
  instantOpening: 'Instant Opening',
  autoOpening: 'Auto Opening',
  autoSpin: 'Auto Spin',
  spinLeft: '({amount}) Spin Left',
  caseType: 'Case Type: {type}',
}

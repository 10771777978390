import request from '@/utils/request'

const prefix = '/upload'

export async function uploadFile(fileName: string, fileBlob: Blob | unknown, token: string): Promise<void> {
  try {
    const res = await request.post(
      `${prefix}/generate-upload-signed-url`,
      {
        fileName,
        expireMin: 15
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (res?.data?.url) {
      let attempts = 0
      let success = false

      while (attempts < 4 && !success) {
        attempts += 1
        try {
          const response = await request.put(res?.data?.url, fileBlob, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })

          if (response.status === 200) {
            success = true
          }
        } catch (uploadError) {
          if (attempts < 4) {
            await new Promise((resolve) => setTimeout(resolve, 500))
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
}

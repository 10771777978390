import shared_TR from './shared/tr'
import shared_EN from './shared/en'
import fishtrap_TR from './fishtrap/tr'
import fishtrap_EN from './fishtrap/en'
import plinko_TR from './plinko/tr'
import plinko_EN from './plinko/en'
import mousetrap_TR from './mousetrap/tr'
import mousetrap_EN from './mousetrap/en'
import boxGame_TR from './box-game/tr'
import boxGame_EN from './box-game/en'
import towergame_tr from './towergame/tr'
import towergame_en from './towergame/en'
import { createI18n } from 'vue-i18n'

const instance = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  availableLocales: import.meta.env.VITE_AVAILABLE_LOCALES,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages: {
    tr: {
      fishtrap: {
        ...fishtrap_TR
      },
      plinko: {
        ...plinko_TR
      },
      boxgame: {
        ...boxGame_TR
      },
      general: {
        ...shared_TR
      },
      mousetrap: {
        ...mousetrap_TR
      },
      towergame: { ...towergame_tr }
    },
    en: {
      fishtrap: {
        ...fishtrap_EN
      },
      plinko: {
        ...plinko_EN
      },
      boxgame: {
        ...boxGame_EN
      },
      general: {
        ...shared_EN
      },
      mousetrap: {
        ...mousetrap_EN
      },
      towergame: { ...towergame_en }
    }
  }
})

export const useLocale = {
  ...instance.global
}

export default instance

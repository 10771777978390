<script setup lang="ts">
import { RouterView } from 'vue-router'
import { ref } from 'vue'
import LoadingJson from '@/assets/fishtrap/loading.json'
import { LottieAnimation } from 'lottie-web-vue'
import LoaderAnimation from '@/components/shared/LoaderAnimation.vue'
import { LoadingStatusHelper } from './utils/helper'

const showPageLoad = ref(true)
const loadingPercent = ref(0)
const perfData = window.performance.timing
const estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart)
const loadTime = Number((estimatedTime / 1000) % 60) * 100

const step = loadTime / 100
const interval = setInterval(() => {
  loadingPercent.value++

  if (loadingPercent.value >= 100) {
    setTimeout(() => {
      showPageLoad.value = false
      LoadingStatusHelper.set(true)
    }, 500)
    clearInterval(interval)
  }
}, step)
</script>

<template>
  <LoaderAnimation v-if="showPageLoad" :loading-stat="loadingPercent">
    <LottieAnimation :animation-data="LoadingJson" :loop="false" :auto-play="true" :speed="2" />
  </LoaderAnimation>
  <RouterView />
</template>

export default {
  difficulty: {
    easy: 'KOLAY',
    medium: 'ORTA',
    hard: 'ZOR'
  },
  tabs: {
    recent: 'Son Kazananlar',
    bests: 'En İyiler',
    my_games: 'Bahislerim'
  },
  common: {
    how2play: 'Nasıl Oynanır ?',
    min: 'min',
    max: 'max',
    play: 'OYNA',
    take: 'BİTİR',
    cancel: 'İPTAL',
    player: 'Oyuncu',
    bet: 'Bahis',
    income: 'Kazanç',
    step: 'Adım',
    game_title: 'Tower Game',
    verify_ticket: 'Bileti Doğrula',
    success: 'Tebrikler',
    uwon: 'Kazandınız!',
    player_info: 'Oyuncu Bilgileri',
    statistics: 'İstatistikler',
    close: 'Kapat',
    ticket_id: 'Bilet #',
    player_hash: 'Oyuncu Anahtarı',
    round_hash: 'Tur Anahtarı',
    secret_hash: 'Özel Anahtar'
  }
}

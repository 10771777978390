export default {
  difficultyOptions: {
    easy: 'Kolay',
    medium: 'Orta',
    hard: 'Zor'
  },
  players: 'Oynayan',
  modal: {
    howToPlay: {
      title: 'MouseTrap'
    }
  }
}
